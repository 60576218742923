<style lang="scss">
.memosticktaschenposter {
  .single-row {
    margin-bottom: 20px;
    h3 {
      &:after {
        content: '';
        display: block;
        width: 100px;
        height: 3px;
        margin: 10px 0;
        background: #ccc;
      }
    }
    img {
      max-height: 400px;
      display: block;
      margin: 20px auto;
    }
  }
  .preis-anfrage {
    text-align: left;
    span {
      display: inline-block;
      padding: 10px 15px;
      background: #eee;
    }
  }
  .download-pdf {
    a {
      max-width: 100%;
    }
  }
  h3 {
    margin-top: 0;
  }
}
</style>

<template>
  <div class="memosticktaschenposter content container">
    <h1>Memostick</h1>
    <div class="row margin-50-0 single-row">
      <div class="col-lg-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/memostick.png" alt="Memostick" class="img-fluid" />
      </div>
      <div class="col-lg-6 justify-content-center align-self-center">
        <div class="txt">
          <h3>Ihre Botschaft bleibt haften.</h3>
          <p>Einfach ablösen und wo anders wieder anbringen: Ein Memostick ist genauso praktisch wie eine Haftnotitz und eignet sich ideal für die Ankündigung von Aktionen – mit Stanzungen, Duftlack oder Nummerierung bietet diese Werbeform viele individuelle Gestaltungsmöglichkeiten. On top: die exklusive Platzierung auf der Titelseite.</p>
          <div class="preis-box">
            <span><strong>Tarif</strong> auf Anfrage.</span>
          </div>
        </div>
      </div>
    </div>
    <br />

    <!--<div class="row">
      <div class="col-lg-6">
        <div class="download-pdf">
          <div class="line"><span>Download</span></div>
          <a href="#">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                MemoStick
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>434 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="download-pdf">
          <div class="line"><span>Download</span></div>
          <a href="#">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Taschenposter
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>434 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';

export default defineComponent({
  name: 'Memostick',
  components: {
    //Modal,
  },
});
</script>
